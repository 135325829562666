import React, { useState } from "react";
import EmailInvites from "./EmailInvites";
import BannerInvites from "./BannerInvites";
import TextInvites from "./TextInvites";
import { useTranslation } from "react-i18next";
import SocialInvites from "./SocialInvites";
import InvitesHistory from "./InvitesHistory";

const PromotionNavigation = (props) => {
    const { t } = useTranslation()

    return (
        <div className="card p-3 promotion_tool_sec">
            <div className="tab-content bg-white " id="nav-tabContent">
                <nav>
                    <div className="nav nav-tabs mb-0 border-0" id="nav-tab" role="tablist">
                        <button
                            className={`nav-link ${props.tab === 'email' ? 'active' : ''}`}
                            id="nav-email-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-email"
                            type="button"
                            role="tab"
                            aria-controls="nav-email"
                            aria-selected={props.tab === 'email'}
                            onClick={() => props.setTab('email')}
                        >
                            {t('emailInvite')}
                        </button>
                        <button
                            className={`nav-link ${props.tab === 'banner' ? 'active' : ''}`}
                            id="nav-banner-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-banner"
                            type="button"
                            role="tab"
                            aria-controls="nav-banner"
                            aria-selected={props.tab === 'banner'}
                            onClick={() => props.setTab('banner')}
                        >
                            {t('bannerInvite')}
                        </button>
                        <button
                            className={`nav-link ${props.tab === 'text' ? 'active' : ''}`}
                            id="nav-text-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-text"
                            type="button"
                            role="tab"
                            aria-controls="nav-text"
                            aria-selected={props.tab === 'text'}
                            onClick={() => props.setTab('text')}
                        >
                            {t('textInvite')}
                        </button>
                        <button
                            className={`nav-link ${props.tab === 'social' ? 'active' : ''}`}
                            id="nav-social-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-social"
                            type="button"
                            role="tab"
                            aria-controls="nav-social"
                            aria-selected={props.tab === 'social'}
                            onClick={() => props.setTab('social')}
                        >
                            {t('socialInvite')}
                        </button>
                        <button
                            className={`nav-link ${props.tab === 'invites-history' ? 'active' : ''}`}
                            id="nav-social-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-social"
                            type="button"
                            role="tab"
                            aria-controls="nav-social"
                            aria-selected={props.tab === 'social'}
                            onClick={() => props.setTab('invites-history')}
                        >
                            {t('invitesHistory')}
                        </button>
                    </div>
                </nav>
                <div className="tab-content p-3 border bg-white">
                    {props.tab === "email" && <EmailInvites data={props.data.data} isLoading={props.isLoading}/>}
                    {props.tab === "banner" && <BannerInvites data={props.data.data} isLoading={props.isLoading}/>}
                    {props.tab === "text" && <TextInvites data={props.data.data} isLoading={props.isLoading}/>}
                    {props.tab === "social" && <SocialInvites data={props.data.data} isLoading={props.isLoading}/>}
                    {props.tab === "invites-history" && <InvitesHistory />}
                </div>
            </div>
        </div>
    )
};

export default PromotionNavigation;