//live
const BASE_URL = "https://admin.8innova.biz/node-api/";
const DEFAULT_KEY = '5b3d0df9-a023-426f-943a-2844223b07b2'

// local
// const BASE_URL = "http://localhost:5000/node-api/";
// const DEFAULT_KEY = 'c26436e7-e388-4088-adc1-563b6f496907'

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
